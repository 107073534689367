<template>
  <div
    class="d-flex flex-column flex-grow-1"
    v-if="document.id"
  >
    <document-toolbar
      :tab="tab"
      :hasComment="!!(document.comment || '').length"
      comment
      v-on="$listeners"
      @close-pop-up="refreshCache()"
    >
      <announcement-status
        class="ml-3"
        :status="announcementStatus"
        outlinedChip
      >
      </announcement-status>
    </document-toolbar>
    <document-in-tab>
      <comment
        :text.sync="document.comment"
        :disabled="!document.canUpdate"
        :showComment="tab.showComment"
        :counter="2000"
        :rules="commentRules"
        class="mb-5"
      />
      <v-card class="mb-6">
        <v-card-title>
          {{$t('t.Informations')}}
        </v-card-title>
        <v-card-text>
          <allocation-announcements-infos :document="document" />
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title>
          {{$t('t.Invoices')}}
        </v-card-title>
        <v-card-text>
          <allocation-announcements-invoices :document="document" />
        </v-card-text>
      </v-card>
    </document-in-tab>
  </div>
</template>

<script>

import DocumentEditor from '@/mixins/document-editor'

export default {
  mixins: [DocumentEditor],
  components: {
    Comment: () => import('@/components/comment'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    AllocationAnnouncementsInvoices: () => import('./allocation-announcements-invoices'),
    AllocationAnnouncementsInfos: () => import('./allocation-announcements-infos'),
    AnnouncementStatus: () => import('@/components/announcement-status')
  },
  async created () {
    Object.assign(this.tab, { cancel: this.cancel })
  },
  data () {
    return {
      commentRules: [value => (value?.length ?? 0) <= 2000]
    }
  },
  computed: {
    announcementStatus () {
      return {
        id: this.document.announcementStatus.value,
        targetDate: this.document.targetDate,
        isBeyondTarget: this.document.announcementStatus.isBeyondTarget,
        statusChangedAt: this.document.updateAt,
        text: this.document.announcementStatus.text,
        isClosed: this.document.announcementStatus.isClosed
      }
    },
    cacheType () {
      return DocumentEditor.CacheType.AllocationAnnouncementDetail
    },
    id () {
      return this.tab.documentId
    }
  },
  methods: {
    async refreshDocumentHook () {
      if (!this.document?.id) { return }
      this.$emit('document-title-change', { tab: this.tab, value: `${this.tab.typeName} ${this.document.number}` })

      if (this.document.comment?.length) {
        this.$emit('document-comment', { tab: this.tab, value: true })
      }

      this.$emit('document-ready', this.tab)
    },
    cancel () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    }
  },
  props: {
    tab: Object
  }
}
</script>

<style lang="stylus" scoped>
.document-list
  height 400px
</style>
